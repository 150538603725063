import { IonContent, IonPage } from "@ionic/react";
import axios, { CancelTokenSource } from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getJWT } from "../../api/localStorage";
import { REACT_APP_API_URL } from "../../env";
import * as H from "history";
import "./index.scss";
import DownloadAppAlert from "../../components/DownloadAppAlert";
import { Network } from "@capacitor/network";

const GOOD_JWT_PATH = "/emergencies"; // The path to redirect to if the JWT is valid
const BAD_JWT_PATH = "/login"; // The path to redirect to if the JWT is invalid or expired
const CHECK_JWT_TIMEOUT = 15_000; // Cancel the request after 15 seconds
const MINIMUM_SPLASH_TIME = 2_000; // Show the splash screen for at least 2 seconds

function minTimeNavigation(
  startTime: number,
  route: string,
  history: H.History
) {
  const remainingTime =
    MINIMUM_SPLASH_TIME - (new Date().getTime() - startTime);
  if (remainingTime > 0) {
    setTimeout(() => {
      history.push(route);
    }, remainingTime);
  } else {
    history.push(route);
  }
}

// This page is used to check if the JWT is valid
export const SplashPage: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const nextRoute = new URLSearchParams(window.location.search).get(
      "nextRoute"
    );

    const startTime = new Date().getTime();
    const checkJWT = async () => {
      const { connected } = await Network.getStatus();
      if (!connected) {
        minTimeNavigation(startTime, GOOD_JWT_PATH, history); //if not connected, we're just using cache!
        return;
      }

      let cancelSource: CancelTokenSource | undefined = undefined;

      const token = await getJWT()
        .then((token) => token)
        .catch(() => undefined);

      const user = await axios
        .get(`${REACT_APP_API_URL}/user`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .catch(() => undefined);

      if (!user) {
        minTimeNavigation(startTime, BAD_JWT_PATH, history);
      }

      try {
        cancelSource = axios.CancelToken.source();
      } catch (e) {
        // Do nothing (the JWT check can proceed without a cancelSource)
      }

      // Check if the JWT is valid by making a request to a protected API endpoint
      axios
        .get(`${REACT_APP_API_URL}/user`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelSource?.token,
          timeout: CHECK_JWT_TIMEOUT,
        })
        .then((res) => {
          // if the query returns an OK status the JWT is not expired
          if (res.status === 200) {
            minTimeNavigation(
              startTime,
              nextRoute ? nextRoute : GOOD_JWT_PATH,
              history
            );
            return;
          }
          minTimeNavigation(
            startTime,
            nextRoute ? `${BAD_JWT_PATH}?nextRoute=${nextRoute}` : BAD_JWT_PATH,
            history
          );
        })
        .catch(() => {
          minTimeNavigation(
            startTime,
            nextRoute ? `${BAD_JWT_PATH}?nextRoute=${nextRoute}` : BAD_JWT_PATH,
            history
          );
        });

      return () => {
        // Try to cancel the request if the component unmounts
        cancelSource?.cancel("Unmounting");
      };
    };

    checkJWT();
  }, []);

  return (
    <IonPage className="splash-container">
      <IonContent>
        <DownloadAppAlert variant="floating" />
        <main className="splash-content">
          <div className="hero"></div>
          <section>
            <h1 className="ion-no-padding ion-no-margin">BioResponse NSW</h1>
            <img
              src="/assets/nsw-government-logo.webp"
              alt="NSW Government logo"
            />
          </section>
        </main>
      </IonContent>
    </IonPage>
  );
};
