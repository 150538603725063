import { Card, Text } from "../../components/ui";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Toggle from "../../components/ui/Toggle";
import Divider from "../../components/ui/Divider";
import { useEffect, useMemo, useState } from "react";
import {
  pushNotificationsEnabled,
} from "../../api/firebase";
import { UserDetails } from "../../project-types";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import { useDevice } from "../../hooks/useDevice";
import FullscreenLoader from "../../components/ui/FullscreenLoader";

type NotificationsProps = {
  user: UserDetails;
  onUpdate: (updatedUser: Partial<UserDetails>) => void;
  onLoad: (options: { title: string }) => void;
};

const toggleFields = [
  {
    title: "New emergencies",
    description:
      "Alerts for when new biosecurity emergencies and outbreaks are announced in NSW.",
    field: "notificationNewEmergency",
  },
  {
    title: "New tasks",
    description:
      "Alerts for when there’s a new task for one of your properties.",
    field: "notificationNewTask",
  },
  {
    title: "Government updates",
    description:
      "Alerts for government updates on emergencies that you are affected by.",
    field: "notificationGovUpdate",
  },
  {
    title: "Emergency zone change",
    description:
      "Alerts for when your properties are affected by an new emergency zone.",
    field: "notificationZoneRuleChange",
  },
];

const Notifications = ({ user, onUpdate, onLoad }: NotificationsProps) => {
  const [pnEnabled, setPnEnabled] = useState<boolean>();
  const { isMobile } = useDevice();

  const handleOnChange = (checked: boolean, property: string) => {
    onUpdate({
      [property]: checked,
    });
  };

  useEffect(() => {
    onLoad({
      title: "Notifications",
    });
    pushNotificationsEnabled().then((enabled) => {
      setPnEnabled(enabled);
    });
  }, []);

  const allNotifications = useMemo(() => {
    return toggleFields.reduce((acc, { field }) => {
      return acc && (user as any)[field];
    }, true);
  }, [user]);

  return (
    <>
      {pnEnabled === undefined && <FullscreenLoader />}
      {pnEnabled === false && (
        <Card customColor="#F7E7EB">
          <Text type="heading" size="small">
            Push notifications are off.{" "}
            <span
              style={{ borderBottom: "black 2px solid", fontWeight: 600 }}
              onClick={() =>
                pushNotificationsEnabled().then((enabled) => {
                  if (!enabled) {
                    if (isMobile) {
                      try {
                        NativeSettings.open({
                          optionAndroid: AndroidSettings.Location,
                          optionIOS: IOSSettings.App,
                        });
                      } catch (e) {
                        console.error({ e });
                      }
                    } else
                      alert(
                        "Please enable push notifications in your browser settings"
                      );
                  }
                  setPnEnabled(enabled);
                })
              }
            >
              Go to settings
            </span>
          </Text>
        </Card>
      )}
      {pnEnabled === true && (
        <IonGrid>
          <div>
            <IonRow>
              <IonCol>
                <Text type="heading" size="small" bold>
                  All notifications
                </Text>
              </IonCol>
              <IonCol size="2">
                <Toggle
                  checked={allNotifications}
                  onChange={(checked) => {
                    const updateObject: Record<string, boolean> = {};
                    toggleFields.forEach(({ field }) => {
                      updateObject[field] = checked;
                    });
                    onUpdate(updateObject);
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="10">
                <Text type="body" size="small">
                  Control global notification settings.
                </Text>
              </IonCol>
            </IonRow>
            <Divider className="margin-y-32" />
          </div>
          {toggleFields.map(({ title, description, field }, index) => {
            return (
              <div key={title} className="ion-margin-bottom">
                <IonRow>
                  <IonCol>
                    <Text type="heading" size="small" bold>
                      {title}
                    </Text>
                  </IonCol>
                  <IonCol size="2">
                    <Toggle
                      checked={(user as any)[field]}
                      onChange={(checked) => handleOnChange(checked, field)}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="10">
                    <Text type="body" size="small">
                      {description}
                    </Text>
                  </IonCol>
                </IonRow>
                {index < toggleFields.length - 1 && (
                  <Divider variant="secondary" className="margin-y-16" />
                )}
              </div>
            );
          })}
        </IonGrid>
      )}
    </>
  );
};

export default Notifications;
