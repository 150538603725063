import { isPlatform } from "@ionic/react";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";

export type MainSettings = Array<{
  title: string;
  menus: Array<{
    name: string;
    isGuestViewable: boolean;
    isHidden?: boolean;
    callback?: () => void;
    externalURL?: string;
    email?: string;
  }>;
}>;

export type Option = {
  name: string;
  info: string;
  checked?: boolean;
  action?: () => void;
};

const isApp = isPlatform("capacitor");
const isIos = isApp && isPlatform("android");
const isAndroid = isApp && isPlatform("ios");

export const mainSettings: MainSettings = [
  {
    title: "Settings",
    menus: [
      { name: "Personal information", isGuestViewable: false },
      { name: "Login & security", isGuestViewable: false },
      { name: "Notifications", isGuestViewable: false },
      { name: "Privacy & sharing", isGuestViewable: false },
    ],
  },
  {
    title: "Permissions",
    menus: [
      {
        name: "Location services",
        callback: () => {
          /**
           * Note that the only supported option by Apple is "App".
           * Using other options might break in future iOS versions
           * or have your app rejected from the App Store.
           */
          try {
            NativeSettings.open({
              optionAndroid: AndroidSettings.Location,
              optionIOS: IOSSettings.App,
            });
          } catch (e) {
            console.error({ e });
          }
        },
        isGuestViewable: true,
        isHidden: !isIos && !isAndroid,
      },
    ],
  },
  {
    title: "Feedback",
    menus: [
      {
        name: "Let us know how to improve",
        externalURL:
          "https://forms.office.com/Pages/ResponsePage.aspx?id=IYjvljkqHEe4mmewgz3TuYvJLJqEmwZIiBgXMtWamjBUNzhCMzBMQ0FVVDkyOFZVVjNTTDBPTU9PUSQlQCN0PWcu",
        isGuestViewable: true,
      },
      {
        name: "Customer Support",
        email: "bioresponsensw@dpi.nsw.gov.au",
        isGuestViewable: true,
      },
    ],
  },
  {
    title: "Legal",
    menus: [
      {
        name: "Privacy policy",
        externalURL:
          "https://www.nsw.gov.au/departments-and-agencies/department-of-regional-nsw/access-information/privacy-management-plan",
        isGuestViewable: true,
      },
    ],
  },
];
