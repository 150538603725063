import { IonCol, IonRow, IonGrid, IonIcon } from "@ionic/react";
import { useState, useEffect, useContext } from "react";
import {
  alertCircleOutline,
  arrowForwardOutline,
  warning,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { AgResponsePage } from "../../components/AgResponsePage";
import { Text, Card, Tag } from "../../components/ui";
import { getEmergencies } from "../../api/emergencies";
import { NavFooter } from "../../components/NavFooter";
import { AgMap } from "../../components/AgMap";
import { GeoJSON } from "react-leaflet";
import EmptyState from "../../components/ui/EmptyState";
import { EmergencyDetails } from "../../project-types";
import formatDate from "../../utils/formatDate";
import { zoneColors } from "../../theme/zoneColours";
import { getProperties } from "../../api/user";
import { FullscreenLoader } from "../../components/ui/FullscreenLoader";
import "./index.scss";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import { isPropertyAffected, isPropertyInZone } from "../../utils/properties";
import { assetIncludesAllProperties } from "../../utils/emergencies";
import { isGuestUser } from "../../api/guest";
import { getNotificationsFlag } from "../../api/localStorage";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { NotificationsCta } from "../../components/bottomSheet/NotificationsCta";
import {
  isNotificationPromptStatus,
  setupNotifications,
} from "../../api/firebase";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";

export const AllEmergenciesPage: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [emergencies, setEmergencies] = useState<
    Array<
      Pick<
        EmergencyDetails,
        "id" | "emergencyTitle" | "assets" | "updated" | "mapData"
      > & {
        propertiesAffected: number;
      }
    >
  >([]);
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);
  const {
    props: { isOpen: isBottomSheetOpen },
    setBottomSheetProps,
  } = useContext(BottomSheetContext);

  useEffect(() => {
    setupNotifications((link) => {
      const [url, ...queries] = link.split("&");
      const newUrl = url.split("=")[1] + "?" + queries.join("&");

      history.push(newUrl);
    });
  }, []);

  const [
    hasNotificationsBottomSheetBeenShown,
    setHasNotificationsBottomSheetBeenShown,
  ] = useState<boolean>(false);
  useEffect(() => {
    const askForUserNotificationPermissions = async () => {
      const isGuest = await isGuestUser();

      const promptStatus = await isNotificationPromptStatus();
      const notificationsFlag = await getNotificationsFlag();

      // if there isn't an existing bottom sheet shown
      // and the user not a guest user,
      // and the user hasn't previously allowed/disallowed notification status
      // and the user hasn't previously made an ambiguous choice of ask me later
      const showBottomSheet =
        !hasNotificationsBottomSheetBeenShown &&
        !isBottomSheetOpen &&
        !isGuest &&
        (promptStatus === "prompt" ||
          promptStatus === "prompt-with-rationale") &&
        notificationsFlag === "maybe";
      // then show the bottom sheet
      if (showBottomSheet) {
        setHasNotificationsBottomSheetBeenShown(true);
        setBottomSheetProps({
          isOpen: true,
          isCloseCtaVisible: false,
          children: <NotificationsCta />,
        });
      }
    };

    askForUserNotificationPermissions();
  }, [isBottomSheetOpen, hasNotificationsBottomSheetBeenShown]);

  const {
    data: emergenciesData,
    state: {
      hasLiveErrored: emergencyLiveErrored,
      haveBothLoaded: emergencyBothLoaded,
    },
  } = useCacheLiveData(getEmergencies);
  const { data: propertiesData } = useCacheLiveData(getProperties);

  useEffect(() => {
    if (emergencyLiveErrored) {
      showDismissableSnackbar("Error retrieving new all emergencies");
    }

    if (emergenciesData === undefined) {
      if (emergencyBothLoaded) {
        loading && setLoading(false);
        setEmergencies([]);
      }
      return;
    }

    const augmentedData = emergenciesData
      .sort((a, b) => {
        //parse updated date to compare
        const aDate = new Date(a.updated);
        const bDate = new Date(b.updated);
        return bDate.getTime() - aDate.getTime();
      })
      .map((emergency) => {
        const { assets } = emergency;
        const hasAllPropertiesAsset = assetIncludesAllProperties(emergency);

        const propertiesAffected = (propertiesData || []).filter((property) => {
          return isPropertyAffected(property, emergency);
        });

        const affected = propertiesAffected.reduce((acc, property) => {
          const features = emergency.mapData?.features;
          if (!features) {
            return acc;
          }
          const num = features.reduce((acc, feature) => {
            return acc || isPropertyInZone(property, feature);
          }, false)
            ? 1
            : 0;
          return acc + num;
        }, 0);

        return {
          ...emergency,
          propertiesAffected: affected ? affected : 0,
          assets: hasAllPropertiesAsset ? [] : assets,
        };
      });

    setEmergencies(augmentedData);
    setLoading(false);
  }, [emergenciesData, propertiesData]);

  return (
    <AgResponsePage footer={<NavFooter />}>
      <Text
        as="h1"
        type="display"
        size="large"
        className="margin-bottom-32 margin-top-0"
      >
        All emergencies
      </Text>

      {loading && <FullscreenLoader />}
      <IonGrid className="padding-0">
        <IonRow className="ion-justify-content-between">
          {emergencies.length > 0 &&
            emergencies.map(
              ({
                id,
                assets,
                emergencyTitle,
                updated,
                mapData,
                propertiesAffected,
              }) => (
                <IonCol
                  size="12"
                  size-md="6"
                  key={id}
                  className="emergency-grid"
                >
                  <div
                    key={id}
                    className="emergency-container"
                    onClick={() => history.push(`/emergency/${id}`)}
                  >
                    {propertiesAffected > 0 && (
                      <div className="num-of-properties-tag">
                        <Tag variant="warning">
                          {
                            <>
                              <span>{propertiesAffected}</span> of your
                              properties affected
                            </>
                          }
                        </Tag>
                      </div>
                    )}
                    <Card
                      map={
                        <div
                          style={{
                            height: "178px",
                            display: "flex",
                            flexDirection: "column",
                            pointerEvents: "none",
                          }}
                        >
                          <AgMap
                            style={{
                              flexGrow: 1,
                            }}
                            nonInteractive
                            geojsonFocus={mapData}
                          >
                            {mapData &&
                              mapData.features.map((feature, i) => {
                                return (
                                  <GeoJSON
                                    key={`${feature.type}-${i}`}
                                    data={feature}
                                    style={{
                                      fillColor: feature.properties.zoneType
                                        ? zoneColors[
                                            feature.properties.zoneType - 1
                                          ]
                                        : i < 2
                                        ? zoneColors[i]
                                        : zoneColors[2],
                                      fillOpacity: 0.5,
                                      weight: 0,
                                    }}
                                  />
                                );
                              })}
                          </AgMap>
                        </div>
                      }
                    >
                      <IonGrid className="ion-padding-top ion-padding-bottom">
                        <IonRow className="ion-align-items-end">
                          <IonCol size="12">
                            {assets.map((asset) => (
                              <Tag variant="secondary" key={asset}>
                                {asset}
                              </Tag>
                            ))}
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-end">
                          <IonCol size="10">
                            <IonRow>
                              <IonCol>
                                <Text type="heading" size="large" as="h2">
                                  {emergencyTitle}
                                </Text>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol>
                                <Text
                                  size="medium"
                                  type="body"
                                  as="p"
                                  className="last-updated"
                                >
                                  Last updated: {formatDate(updated)}
                                </Text>
                              </IonCol>
                            </IonRow>
                          </IonCol>
                          <IonCol size="2" className="ion-text-end">
                            <Text
                              type="heading"
                              size="large"
                              color="primary"
                              as="span"
                            >
                              <IonIcon icon={arrowForwardOutline} />
                            </Text>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </Card>
                  </div>
                </IonCol>
              )
            )}
        </IonRow>
      </IonGrid>
      {/* <IonList className="ion-no-padding">
       
      </IonList> */}
      {!loading && emergencies.length <= 0 && (
        <EmptyState
          {...(emergencyBothLoaded && emergencyLiveErrored
            ? {
                icon: warning,
                header: "Offline Mode",
                body: "We are unable to retrieve emergencies. Please check your internet connection.",
              }
            : {
                icon: alertCircleOutline,
                header: "All clear!",
                body: "There are currently no biosecurity emergencies in NSW",
              })}
        />
      )}
    </AgResponsePage>
  );
};
